import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../../components/Layout";

export default class BlogPage extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <Layout>
        <section className="section">
          <div className="container is-narrow">
            {posts.map(({ node: post }) => (
              <div className="content content--blog" key={post.id}>
                <Link className="has-text-primary" to={post.fields.slug}>
                  <h1 className="has-text-weight-bold is-size-2 headline">
                    {post.frontmatter.title}
                  </h1>
                </Link>

                <div className="subhead">{post.frontmatter.date}</div>

                {post.excerpt}
                <br />
                <br />
                <Link
                  className="button is-medium is-dark"
                  to={post.fields.slug}
                >
                  Keep Reading →
                </Link>
              </div>
            ))}
          </div>
        </section>
      </Layout>
    );
  }
}

BlogPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export const blogPageQuery = graphql`
  query BlogQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
